import S3 from 'react-aws-s3'
import Config from '../config'

const config = {
  bucketName: 'car-rental-static-assets',
  dirName: `documents/${Config.ENV}`,
  region: 'us-west-2',
  accessKeyId: Config.AWS_ACCESS_KEY_ID,
  secretAccessKey: Config.AWS_SECRET_ACCESS_KEY
  // s3Url: 'https:/your-custom-s3-url.com/', /* optional */
}

const ReactS3Client = new S3(config)
export default ReactS3Client
