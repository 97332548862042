import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { LOGGER } from '../../utils/Logger'
import Config from '../../config'
import CustomLoaderSpinner from '../../components/CustomLoaderSpinner'
import GeneralStyles from '../styles/GeneralStyles.module.scss'
import axios from 'axios'
import DDEChauffeurPdfRenderer from '../../components/contracts/DDEChauffeurPdfRenderer'
import DDERentalPostPdfRenderer from '../../components/contracts/DDERentalPostPdfRenderer'
import DDERentalPrePdfRenderer from '../../components/contracts/DDERentalPrePdfRenderer'
import CustomButtonContained from '../../components/CustomButtonContained'
import Checkmark from '../../assets/checkMark.gif'
import ReactS3Client from '../../services/S3Client'
import { CONTRACT_TYPES, EMAIL_SQS_TYPE } from '../../metadata/enums'
import Colors from '../../config/colors'
import ClientsMap from '../../metadata/ClientsMap'
import { auth } from '../../services/firebase'
import { onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth'

import DriveChauffeurRendererNew from '../../components/contracts/DriveChauffeurRendererNew'
import DriveRentalRendererNew from '../../components/contracts/DriveRentalRendererNew'
import QualityRentalRendererNew from '../../components/contracts/QualityRentalRenderer'
import Helpers from '../../utils/Helpers'
import AMDRentalRenderer from '../../components/contracts/AMDRentalRenderer'

const Contract = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()

  const [backendUrl, setBackendUrl] = useState(null)
  const [reservation, setReservation] = useState(null)
  const [bus, setBus] = useState(null)
  const [resId, setResId] = useState(null)
  const [token, setToken] = useState(null)
  const [loading, setLoading] = useState(true)
  const [saved, setSaved] = useState(false)
  const [shouldSave, setShouldSave] = useState(false)
  const [contractType, setContractType] = useState(null)
  const [contractExists, setContractExists] = useState(false)
  const [businessInfo, setBusinessInfo] = useState(null)
  const [isCustomer, setIsCustomer] = useState(null)
  const [shouldSendInvoice, setShouldSendInvoice] = useState(false)
  const [checkSignatures, setCheckSignatures] = useState(false)
  const [priceSettings, setPriceSettings] = useState(null)
  const [pricing, setPricing] = useState(null)

  useEffect(() => {
    if (location.pathname.includes('chauffeur')) {
      setContractType(CONTRACT_TYPES.chauffeur)
    } else {
      if (location.pathname.includes('post')) {
        setContractType(CONTRACT_TYPES.rental_post)
      } else {
        setContractType(CONTRACT_TYPES.rental_pre)
      }
    }

    if (location.pathname.includes('customer')) {
      setIsCustomer(true)
    } else {
      setIsCustomer(false)
    }
  }, [location.pathname])

  useEffect(() => {
    const listener = onAuthStateChanged(auth, async (authUser) => {
      console.log('auth state cahnged', authUser)
      if (authUser) {
        auth.signOut()
        const authToken = await authUser.getIdToken(true)
        const resId = searchParams.get('_id')
        const bus = searchParams.get('bus')
        const client = ClientsMap[bus]
        if (!client) {
          window.alert('Cannot find business information')
          return
        }
        setResId(resId)
        setBackendUrl(client.url)
        setBus(bus)
        setToken(authToken)
        getBusinessDetails(client.url, authToken)
        getPriceSettings(client.url, authToken)
        getReservation(client.url, authToken, resId)
      }
    })
    return listener
  }, [])

  useEffect(() => {
    if (reservation && priceSettings && Object.keys(priceSettings).length > 0 && contractType) {
      let pricing
      if (contractType === CONTRACT_TYPES.chauffeur) {
        pricing = Helpers.getChauffeurPricingObject(reservation, reservation.vehicle_details, priceSettings)
      } else {
        pricing = Helpers.getRentalsPricingObject(reservation, reservation.vehicle_details, priceSettings)
      }
      setPricing(pricing)
    }
  }, [reservation, priceSettings, contractType])

  useEffect(() => {
    const resId = searchParams.get('_id')
    const bus = searchParams.get('bus')
    if (!resId || !bus || !ClientsMap[bus]) {
      window.alert('invalid link')
    } else {
      signIn()
    }
  }, [])

  useEffect(() => {
    if (!contractType || !reservation) {
      return
    }

    let contract
    if (contractType === CONTRACT_TYPES.chauffeur) {
      contract = reservation.contract
    } else if (contractType === CONTRACT_TYPES.rental_post) {
      contract = reservation.post_contract
    } else {
      contract = reservation.pre_contract
    }

    setContractExists(contract !== null && contract !== undefined)
  }, [contractType, reservation])

  const getBusinessDetails = async (url, token) => {
    const config = {
      method: 'get',
      url: `${url}settings?id=businessDetails`,
      headers: { Authorization: token, contentType: 'application/json' }
    }
    axios(config)
      .then(result => {
        setBusinessInfo(result.data)
      }).catch(err => {
        console.log('error when getting business details', err)
      })
  }

  const getPriceSettings = async (url, token) => {
    const config = {
      method: 'get',
      url: `${url}settings`,
      headers: { Authorization: token }
    }

    try {
      const res = await axios(config)
      const settings = res.data
      settings.forEach(setting => {
        if (setting._id === 'pricing') {
          setPriceSettings(setting)
        }
      })
    } catch (err) {
      LOGGER.error('Error when getting settings data', err)
    }
  }

  const signIn = () => {
    signInWithEmailAndPassword(auth, Config.ADMIN_CREDS[0], Config.ADMIN_CREDS[1])
      .then((userCredential) => {
        // Signed in
        console.log('successfully signed in')
      }).catch(err => {
        console.log('error when signing in user', err)
      })
  }

  const getReservation = async (url, token, id) => {
    const config = {
      method: 'get',
      url: `${url}reservations/${id}`,
      headers: { Authorization: token }
    }

    try {
      const res = await axios(config)
      const reservation = res.data
      setReservation(reservation)
      setLoading(false)
    } catch (err) {
      LOGGER.error('Error when getting reservatuib', err)
      window.alert(`Error when getting reservation details : ${err.response?.data?.error}`)
      return []
    }
  }

  const onDocSend = async (buffer) => {
    setLoading(true)
    const config = {
      method: 'post',
      url: `${backendUrl}mail`,
      headers: { Authorization: token, contentType: 'application/json' },
      data: {
        emailType: EMAIL_SQS_TYPE.RENTAL_INVOICE,
        resId: reservation._id,
        contractType
      }
    }

    try {
      const res = await axios(config)
      console.log('sent the email')
      setLoading(false)
      window.alert('Rental invoice sent to customer email address')
    } catch (err) {
      console.log('error when sending mail request to backend', err)
      window.alert('error when sending email')
      setLoading(false)
    }
  }

  const onSignatureChecked = (flag) => {
    if (!flag) {
      window.alert('Missing signatures. Please complete all the required signatures.')
      setCheckSignatures(false)
    } else {
      // the signatures have been checked, we can
      setCheckSignatures(false)
      onSavePressed()
    }
  }

  const onDocSavedApryse = async (blob) => {
    setLoading(true)
    // let blobUrl = URL.createObjectURL(blob)
    console.log('uploading to s3')
    ReactS3Client
      .uploadFile(blob)
      .then(data => {
        // LOGGER.log('success', data)
        const url = Config.AWS_CLOUDFRONT_URL + data.key
        updateReservation(url)
      })
      .catch(err => LOGGER.error('error ', err))
  }

  const onDocSaved = async (buffer) => {
    setLoading(true)
    const blob = new Blob([buffer], { type: 'application/pdf' })
    // let blobUrl = URL.createObjectURL(blob)
    console.log('uploading to s3')
    ReactS3Client
      .uploadFile(blob)
      .then(data => {
        // LOGGER.log('success', data)
        const url = Config.AWS_CLOUDFRONT_URL + data.key
        updateReservation(url)
      })
      .catch(err => LOGGER.error('error ', err))
  }

  const updateReservation = async (url) => {
    const data = JSON.parse(JSON.stringify(reservation))
    let toSave
    if (contractType === CONTRACT_TYPES.chauffeur) {
      data.contract = {
        url,
        saved_at: new Date()
      }
    } else if (contractType === CONTRACT_TYPES.rental_post) {
      data.post_contract = {
        url,
        saved_at: new Date()
      }
    } else {
      data.pre_contract = {
        url,
        saved_at: new Date()
      }
    }

    if (data.vehicle_details) {
      delete data.vehicle_details
    }

    if (data.client_details) {
      delete data.client_details
    }

    const config = {
      method: 'put',
      url: `${backendUrl}reservations/${resId}`,
      headers: { Authorization: token, contentType: 'application/json' },
      data
    }

    console.log('updating reserv', data)

    const res = await axios(config)
    console.log('updated reservation')
    setLoading(false)
    setSaved(true)
  }

  const createNewContract = async () => {
    if (!reservation) {
      return
    }

    if (window.confirm('Are you sure you want to generate a new agreement?')) {
      // delete the existing contract and update reservation
      setLoading(true)
      const temp = {}
      const tempReservation = JSON.parse(JSON.stringify(reservation))
      switch (contractType) {
        case CONTRACT_TYPES.chauffeur:
          temp.contract = null
          delete tempReservation.contract
          break
        case CONTRACT_TYPES.rental_post:
          temp.post_contract = null
          delete tempReservation.post_contract
          break
        case CONTRACT_TYPES.rental_pre:
          temp.pre_contract = null
          delete tempReservation.pre_contract
          break
        default:
      }

      const config = {
        method: 'put',
        url: `${backendUrl}reservations/${tempReservation._id}`,
        headers: { Authorization: token },
        data: temp
      }

      try {
        const res = await axios(config)
        console.log('updated reservation')
        setReservation(tempReservation)
        setLoading(false)
      } catch (err) {
        LOGGER.error('Error when updating reservsation', err)
        return []
      }
    }
  }

  const sendSignatureRequestEmail = async () => {
    if (!window.confirm('Are you sure you want to send a signature request email to the customer?')) {
      return
    }

    setLoading(true)
    let url
    if (contractType === CONTRACT_TYPES.chauffeur) {
      url = `${Config.CONTRACT_URL}customer/contract/chauffeur?_id=${reservation?._id}&token=${token}&bus=${bus}`
    } else if (contractType === CONTRACT_TYPES.rental_pre) {
      url = `${Config.CONTRACT_URL}customer/contract/rental/pre?_id=${reservation?._id}&token=${token}&bus=${bus}`
    } else {
      url = `${Config.CONTRACT_URL}customer/contract/rental/post?_id=${reservation?._id}&token=${token}&bus=${bus}`
    }

    const config = {
      method: 'post',
      url: `${backendUrl}mail`,
      headers: { Authorization: token, contentType: 'application/json' },
      data: {
        emailType: EMAIL_SQS_TYPE.SIGN_REQUEST,
        resId: reservation._id,
        signUrl: url
      }
    }

    try {
      const res = await axios(config)
      console.log('sent the email')
      setLoading(false)
      window.alert('Signature request email sent to customer')
    } catch (err) {
      console.log('error when sending mail request to backend', err)
      window.alert('error when sending email')
      setLoading(false)
    }
  }

  const onSavePressed = () => {
    const string = 'Are you sure all the required fields are filled and you want to save the contract?'
    if (window.confirm(string)) {
      setShouldSave(true)
    }
  }

  const onSendInvoicePressed = () => {
    const string = 'Are you sure you want to send the invoice to the customer email?'
    if (window.confirm(string)) {
      setShouldSendInvoice(true)
    }
  }

  if (loading) {
    return (
			<div className={GeneralStyles.container}
					 style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
				<CustomLoaderSpinner/>
			</div>
    )
  }

  const renderDDEPdf = () => {
    if (!reservation || !contractType) {
      return null
    }

    return (
			<div style={{ width: '100%', height: '100%', overflowY: 'scroll' }}>
				{
					contractType === CONTRACT_TYPES.chauffeur
					  ? <DDEChauffeurPdfRenderer
							token={token}
							document={'/ddeChauffeurPdf.pdf'}
							reservation={reservation}
							shouldSave={shouldSave}
							onSave={onDocSaved}
							backendUrl={backendUrl}
							shouldSendInvoice={shouldSendInvoice}
							onDocSend={onDocSend}
							checkSignatures={checkSignatures}
							onSignatureChecked={onSignatureChecked}
						/>
					  : contractType === CONTRACT_TYPES.rental_post
					    ? <DDERentalPostPdfRenderer
								token={token}
								document={'/ddeRentalPdfPost.pdf'}
								reservation={reservation}
								shouldSave={shouldSave}
								onSave={onDocSaved}
								backendUrl={backendUrl}
								shouldSendInvoice={shouldSendInvoice}
								onDocSend={onDocSend}
								checkSignatures={checkSignatures}
								onSignatureChecked={onSignatureChecked}
							/>
					    : <DDERentalPrePdfRenderer
								token={token}
								document={'/ddeRentalPdfPre.pdf'}
								reservation={reservation}
								shouldSave={shouldSave}
								onSave={onDocSaved}
								backendUrl={backendUrl}
								shouldSendInvoice={shouldSendInvoice}
								onDocSend={onDocSend}
								checkSignatures={checkSignatures}
								onSignatureChecked={onSignatureChecked}
							/>

				}

			</div>
    )
  }

  const renderDrivePdf = () => {
    if (!reservation || !contractType) {
      return null
    }

    return (
			<div style={{ width: '100%', height: '100%', overflowY: 'scroll' }}>
				{
					contractType === CONTRACT_TYPES.chauffeur
					  ? <DriveChauffeurRendererNew
							pricing={pricing}
							token={token}
							doc={'/driveChauffeurPdfNew.pdf'}
							reservation={reservation}
							shouldSave={shouldSave}
							onSave={onDocSavedApryse}
							backendUrl={backendUrl}
							businessInfo={businessInfo || {}}
							shouldSendInvoice={shouldSendInvoice}
							onDocSend={onDocSend}
							checkSignatures={checkSignatures}
							onSignatureChecked={onSignatureChecked}
						/>
					  : <DriveRentalRendererNew
							pricing={pricing}
							token={token}
							doc={contractType === CONTRACT_TYPES.rental_pre ? '/driveRentalPrePdfNew.pdf' : '/driveRentalPostPdfNew.pdf'}
							reservation={reservation}
							shouldSave={shouldSave}
							onSave={onDocSavedApryse}
							backendUrl={backendUrl}
							businessInfo={businessInfo || {}}
							isPreTrip={contractType === CONTRACT_TYPES.rental_pre}
							shouldSendInvoice={shouldSendInvoice}
							onDocSend={onDocSend}
							checkSignatures={checkSignatures}
							onSignatureChecked={onSignatureChecked}
						/>
				}

			</div>
    )
  }

  const renderTemplatePdf = () => {
    if (!reservation || !contractType) {
      return null
    }

    return (
			<div style={{ width: '100%', height: '100%', overflowY: 'scroll' }}>
				{
					contractType === CONTRACT_TYPES.chauffeur
					  ? <DriveChauffeurRendererNew
							pricing={pricing}
							token={token}
							doc={'/driveChauffeurPdfNew.pdf'}
							reservation={reservation}
							shouldSave={shouldSave}
							onSave={onDocSavedApryse}
							backendUrl={backendUrl}
							businessInfo={businessInfo || {}}
							shouldSendInvoice={shouldSendInvoice}
							onDocSend={onDocSend}
							checkSignatures={checkSignatures}
							onSignatureChecked={onSignatureChecked}
						/>
					  : <DriveRentalRendererNew
							pricing={pricing}
							token={token}
							doc={contractType === CONTRACT_TYPES.rental_pre ? '/driveRentalPrePdfNew.pdf' : '/driveRentalPostPdfNew.pdf'}
							reservation={reservation}
							shouldSave={shouldSave}
							onSave={onDocSavedApryse}
							backendUrl={backendUrl}
							businessInfo={businessInfo || {}}
							isPreTrip={contractType === CONTRACT_TYPES.rental_pre}
							shouldSendInvoice={shouldSendInvoice}
							onDocSend={onDocSend}
							checkSignatures={checkSignatures}
							onSignatureChecked={onSignatureChecked}
						/>
				}

			</div>
    )
  }

  const renderAmdPdf = () => {
    if (!reservation || !contractType) {
      return null
    }

    return (
			<div style={{ width: '100%', height: '100%', overflowY: 'scroll' }}>
				{
					contractType === CONTRACT_TYPES.chauffeur
					  ? <DriveChauffeurRendererNew
							pricing={pricing}
							token={token}
							doc={'/amdChauffeurPdf.pdf'}
							reservation={reservation}
							shouldSave={shouldSave}
							onSave={onDocSavedApryse}
							backendUrl={backendUrl}
							businessInfo={businessInfo || {}}
							shouldSendInvoice={shouldSendInvoice}
							onDocSend={onDocSend}
							checkSignatures={checkSignatures}
							onSignatureChecked={onSignatureChecked}
						/>
					  : <AMDRentalRenderer
							priceSettings={priceSettings}
							pricing={pricing}
							token={token}
							doc={contractType === CONTRACT_TYPES.rental_pre ? '/amdRentalPrePdf.pdf' : '/amdRentalPostPdf.pdf'}
							reservation={reservation}
							shouldSave={shouldSave}
							onSave={onDocSavedApryse}
							backendUrl={backendUrl}
							businessInfo={businessInfo || {}}
							isPreTrip={contractType === CONTRACT_TYPES.rental_pre}
							shouldSendInvoice={shouldSendInvoice}
							onDocSend={onDocSend}
							checkSignatures={checkSignatures}
							onSignatureChecked={onSignatureChecked}
						/>
				}

			</div>
    )
  }

  const renderQualityPdf = () => {
    if (!reservation || !contractType) {
      return null
    }

    return (
			<div style={{ width: '100%', height: '100%', overflowY: 'scroll' }}>
				{
					contractType === CONTRACT_TYPES.chauffeur
					  ? <DriveChauffeurRendererNew
							pricing={pricing}
							token={token}
							doc={'/qualityChauffeurPdf.pdf'}
							reservation={reservation}
							shouldSave={shouldSave}
							onSave={onDocSavedApryse}
							backendUrl={backendUrl}
							businessInfo={businessInfo || {}}
							shouldSendInvoice={shouldSendInvoice}
							onDocSend={onDocSend}
							checkSignatures={checkSignatures}
							onSignatureChecked={onSignatureChecked}
						/>
					  : <QualityRentalRendererNew
							pricing={pricing}
							token={token}
							doc={contractType === CONTRACT_TYPES.rental_pre ? '/qualityRentalPrePdf.pdf' : '/qualityRentalPostPdf.pdf'}
							reservation={reservation}
							shouldSave={shouldSave}
							onSave={onDocSavedApryse}
							backendUrl={backendUrl}
							businessInfo={businessInfo || {}}
							isPreTrip={contractType === CONTRACT_TYPES.rental_pre}
							shouldSendInvoice={shouldSendInvoice}
							onDocSend={onDocSend}
							checkSignatures={checkSignatures}
							onSignatureChecked={onSignatureChecked}
						/>
				}

			</div>
    )
  }

  const renderRightRenderer = () => {
    if (bus === 'dde') {
      // for dde, we show custom pdf
      return renderDDEPdf()
    } else if (bus === 'drivela') {
      return renderDrivePdf()
    } else if (bus === 'drivela2') {
      return renderDrivePdf()
    } else if (bus === 'quality') {
      return renderQualityPdf()
    } else if (bus === 'amd') {
      return renderAmdPdf()
    } else {
      return renderAmdPdf()
    }
  }

  const getActionButtons = () => {
    if (isCustomer) {
      // customer view will not have any other buttons apart from save
      return (
				<div
					style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '100%' }}>
					{
						!contractExists &&
						<CustomButtonContained
							text={'Finish & Save'}
							// onClick={() => setCheckSignatures(true)}
							onClick={onSavePressed}
						/>
					}
				</div>
      )
    } else {
      if (contractExists) {
        return (
					<div style={{
					  display: 'flex',
					  flexDirection: 'row',
					  justifyContent: 'space-evenly',
					  width: '100%'
					}}>
						<CustomButtonContained
							text={'Create New Contract'}
							onClick={createNewContract}
						/>
						{/* <CustomButtonContained */}
						{/*	borderColor={Colors.themeLight} */}
						{/*	textColor={Colors.themeLight} */}
						{/*	style={{backgroundColor: 'transparent'}} */}
						{/*	text={'Send Email Invoice'} */}
						{/*	onClick={onSendInvoicePressed} */}
						{/* /> */}
					</div>
        )
      } else {
        return (
					<div style={{
					  display: 'flex',
					  flexDirection: 'row',
					  justifyContent: 'space-evenly',
					  width: '100%',
					  marginTop: 10
					}}>
						<CustomButtonContained
							style={{ maxWidth: '50%', height: 40 }}
							text={'Finish & Save'}
							onClick={onSavePressed}
						/>
						<CustomButtonContained
							borderColor={Colors.themeLight}
							textColor={Colors.themeLight}
							style={{ backgroundColor: 'transparent', maxWidth: '50%', height: 40 }}
							text={'Send Email'}
							onClick={sendSignatureRequestEmail}
						/>
					</div>
        )
      }
    }
  }

  return (
		<div style={{ width: '100%', height: '100%' }}>
			{
				saved
				  ?					<div style={{ width: '100%', height: '90vh', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
						<img src={Checkmark} style={{ height: 100, width: 100, marginTop: 50 }}/>
						<label style={{ fontSize: 30, marginTop: 10, color: Colors.theme }}>The contract is saved.</label>
						<label style={{ fontSize: 20, color: Colors.theme }}>You can now close this window.</label>
					</div>
				  : <div style={{ width: '100%', height: '100vh' }}>
						<div style={{ height: '93vh' }}>
							{renderRightRenderer()}
						</div>
						{/* <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', marginTop: 10, width: '100%'}}> */}
						{/*	<CustomButtonContained */}
						{/*		text={'Finish & Save'} */}
						{/*		onClick={onSavePressed} */}
						{/*	/> */}
						{/*	{ */}
						{/*		contractExists && */}
						{/*		<CustomButtonContained */}
						{/*			text={'Create New Contract'} */}
						{/*			onClick={createNewContract} */}
						{/*		/> */}
						{/*	} */}
						{/* </div> */}
						{getActionButtons()}
					</div>

			}
		</div>
  )
}

export default Contract
